.select[data-v-02bd21e9] {
  display: flex;
  justify-content: space-between;
}
.el-form-item[data-v-02bd21e9] {
  width: 46% !important;
}
.item_icon[data-v-02bd21e9] {
  display: inline-block;
  padding-right: 5px;
  cursor: pointer;
}
.item_icon i[data-v-02bd21e9] {
  display: inline-block;
  line-height: 13px;
  font-size: 13px;
  margin-right: 4px;
}
.div_p[data-v-02bd21e9] {
  line-height: 23px;
  border-bottom: 1px solid #efefef;
  height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.div_p[data-v-02bd21e9]:last-child {
  border-bottom: none;
}