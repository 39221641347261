.select[data-v-16a8dffb] {
  display: flex;
  justify-content: space-between;
}
.div_p[data-v-16a8dffb] {
  line-height: 23px;
  border-bottom: 1px solid #efefef;
  height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.div_p[data-v-16a8dffb]:last-child {
  border-bottom: none;
}