.record[data-v-7d29f3be] {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
}
.record[data-v-7d29f3be]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
.el-form-item__label[data-v-7d29f3be] {
  font-size: 13px;
}
.content[data-v-7d29f3be] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  position: relative;
}
.content span[data-v-7d29f3be] {
  line-height: 28px;
  font-size: 13px;
}
.content tr[data-v-7d29f3be] {
  padding: 0;
  margin: 0;
}
.content td[data-v-7d29f3be] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 25px;
  padding: 0 6px;
  color: #333;
  font-size: 13px;
}
.center[data-v-7d29f3be] {
  text-align: center;
}
[data-v-7d29f3be] .el-textarea__inner {
  min-height: 80px !important;
}
table[data-v-7d29f3be] .el-input__wrapper {
  box-shadow: none;
  background: none;
  border: none;
}
.boxShadowNone2[data-v-7d29f3be] .el-select-v2__wrapper {
  box-shadow: none;
  background: none;
  border: none;
}
.el-form--inline .el-form-item[data-v-7d29f3be]:nth-child(2n) {
  margin-right: 0;
}
.el-form--inline .el-form-item[data-v-7d29f3be]:last-child {
  margin-right: 0;
}