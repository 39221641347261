.record[data-v-2e08e8fd] {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  padding-bottom: 6px;
  margin-bottom: 10px;
  border-bottom: 1px solid #c2c2c2;
}
.record[data-v-2e08e8fd]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
.hint[data-v-2e08e8fd] {
  color: red;
  margin-left: 30px;
  font-weight: normal !important;
}
.hint .icon-sanjiaotanhao[data-v-2e08e8fd] {
  color: #e6a23c;
  margin-right: 4px;
}
.hint .icon-wenhao[data-v-2e08e8fd] {
  font-size: 13px;
  cursor: pointer;
}
.help[data-v-2e08e8fd] {
  color: #17a2b8;
  font-size: 13px;
  position: absolute;
  right: -44px;
  cursor: pointer;
}
.help i[data-v-2e08e8fd] {
  color: #17a2b8;
  font-size: 13px;
  margin-left: 3px;
}