.top_select[data-v-995ec57c] {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}
.note_box[data-v-995ec57c] {
  height: 500px;
}
.note_content[data-v-995ec57c] {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: flex-end;
  padding-bottom: 20px;
}
.note_content img[data-v-995ec57c] {
  width: 300px;
  margin-top: 5px;
}
.el-form-item[data-v-995ec57c] {
  margin-bottom: 22px !important;
}
.time[data-v-995ec57c] {
  font-size: 18px;
}
.note[data-v-995ec57c] {
  font-size: 14px;
  margin-top: 5px;
}
.foot[data-v-995ec57c] {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.record[data-v-995ec57c] {
  font-size: 14px;
  color: #333;
  font-weight: 600;
}
.record[data-v-995ec57c]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}