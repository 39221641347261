.top_select[data-v-e9ac8b14] {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}
.select_left[data-v-e9ac8b14] {
  display: flex;
}
.every_select[data-v-e9ac8b14] {
  margin-right: 5px;
}
.el-form-item[data-v-e9ac8b14] {
  margin-bottom: 20px !important;
}
.count[data-v-e9ac8b14] {
  font-size: 14px;
  margin-right: 5px;
}
[data-v-e9ac8b14] .el-table__cell {
  padding: 3px 0 !important;
}
.el-select[data-v-e9ac8b14] {
  margin: 0 5px 0 0 !important;
}
[data-v-e9ac8b14] .el-radio-button__original-radio:checked + .el-radio-button__inner {
  background-color: var(--themeColor, #17a2b8);
  border-color: var(--themeColor, #17a2b8);
}
.div_p[data-v-e9ac8b14] {
  line-height: 23px;
  border-bottom: 1px solid #efefef;
  height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.div_p[data-v-e9ac8b14]:last-child {
  border-bottom: none;
}
.content_data[data-v-e9ac8b14] {
  background-color: #d8ecff;
  margin-bottom: 10px;
  font-size: 12px;
  position: relative;
}
.content_data .iconfont[data-v-e9ac8b14] {
  font-size: 18px;
  color: #17a2b8;
}
.each[data-v-e9ac8b14] {
  width: 9%;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #333;
}
.each span[data-v-e9ac8b14] {
  font-size: 13px;
  font-weight: 600;
  color: #000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.title[data-v-e9ac8b14] {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.icon_bg[data-v-e9ac8b14] {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #aedcfb;
  text-align: center;
  line-height: 30px;
}
.data_time[data-v-e9ac8b14] {
  position: absolute;
  right: 10px;
  top: 10px;
}
.each_right[data-v-e9ac8b14] {
  width: calc(100% - 35px);
  margin-left: 5px;
}
.icon-xiala-[data-v-e9ac8b14] {
  font-size: 12px;
  margin-left: 5px;
}