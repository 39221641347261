.main[data-v-54977096] {
  box-shadow: 0 2px 10px #ddd;
  background-color: #fbfcfc;
  padding: 10px;
}
.el-col-sm-8[data-v-54977096] {
  border-right: 1px solid #b9b9b9;
  font-size: 14px;
  height: 34px;
  line-height: 34px;
}
.el-col-sm-8 div[data-v-54977096] {
  padding-left: 10px;
  box-sizing: border-box;
}
.el-col-sm-4[data-v-54977096] {
  border-right: 1px solid #b9b9b9;
  padding-right: 10px;
  font-size: 14px;
  height: 34px;
  line-height: 34px;
}
.el-col-sm-4 div[data-v-54977096] {
  padding-left: 10px;
}
.el-col-sm-20[data-v-54977096] {
  border-right: 1px solid #b9b9b9;
  font-size: 14px;
  height: 34px;
  line-height: 34px;
}
.el-col-sm-20 div[data-v-54977096] {
  padding-left: 10px;
  box-sizing: border-box;
}
.el-row[data-v-54977096] {
  display: flex;
  border-left: 1px solid #b9b9b9;
  border-bottom: 1px solid #b9b9b9;
}
.el-row[data-v-54977096]:first-child {
  border-top: 1px solid #b9b9b9;
}
.el-row[data-v-54977096]:last-child {
  border-bottom: 1px solid #b9b9b9;
}
.top[data-v-54977096] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}
.title[data-v-54977096] {
  margin: 0 auto;
  text-align: center;
}
.title span[data-v-54977096] {
  display: inline-block;
  font-size: 20px;
  color: #333;
  text-align: center;
  border-bottom: 4px double #333;
  padding: 2px 15px;
}
.text[data-v-54977096] {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.pic[data-v-54977096] {
  position: absolute;
  height: 130px;
  left: 110px;
}
.posi-rela[data-v-54977096] {
  margin: 40px 0;
}