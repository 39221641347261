.note_box[data-v-4c159000] {
  height: 500px;
}
.note_content[data-v-4c159000] {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: flex-end;
  padding-bottom: 20px;
}
.time[data-v-4c159000] {
  font-size: 18px;
}
.note[data-v-4c159000] {
  font-size: 14px;
  margin-top: 5px;
}
